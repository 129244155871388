interface DataLayerObject {
  event: "GA4event";
  eventname: string;
  position: number;
  label: string;
  filter: string;
  type: string;
  category: string;
  city: string;
  store: string;
  action: string;
}
declare global {
  interface Window {
    dataLayer: DataLayerObject[];
  }
}

export function dataLayerPush(obj: Partial<DataLayerObject>) {
  if (!window.dataLayer) {
    return console.warn("[ANALYTICS] dataLayer is not defined");
  }
  if (!obj.category) obj.category = "";
  if (!obj.city) obj.city = "";
  if (!obj.event) obj.event = "GA4event";
  if (!obj.eventname) obj.eventname = "";
  if (!obj.filter) obj.filter = "";
  if (!obj.label) obj.label = "";
  if (!obj.position) obj.position = 0;
  if (!obj.store) obj.store = "";
  if (!obj.type) obj.type = "";
  if (!obj.action) obj.action = "";

  window.dataLayer.push(obj as DataLayerObject);
}
