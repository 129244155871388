import {
  Translations,
  CustomRenderer,
  CTAOrigin,
  hostname,
  STORE_TYPE_GAP_CORNER,
  STORE_TYPE_GAP_CORNER_OVS_DIVISION,
  selectStoreIcon,
  createLegend,
  createInfoWindowContent,
} from "./shared";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { getGoogleMapsLink, googleAutocompleteHandler } from "@retailtune/google-maps-utils";
import { Service, Store } from "@retailtune/types-storelocator/lib/store";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Position } from "@retailtune/types/lib/geolocation";
import {
  createAutocompleteHandler,
  createDebounceFn,
  createExpirationValue,
  getExpirable,
  retailTuneAutocompleteHandler,
  RT_API_SERVICES_GET,
  RT_API_STORES_GET,
  RT_API_TIME_GET,
  RT_API_VALIDATE_HOSTNAME,
  setExpirable,
} from "@retailtune/utils";
import {
  createPosition,
  createStoreOpeningTimeText,
  fetchUserDefaultPosition,
  fetchUserPosition,
  getDevice,
  getRadialDistanceFn,
  positionToLatLngLiteral,
  sortStoresByPriority,
  USER_POSITION_CONSENT,
} from "@retailtune/utils-storelocator";
import {
  createAccordion,
  createAutocomplete,
  createScrollButton,
  createSidebar,
  createToastMessagesContainer,
} from "@retailtune/vanilla-ui-core";
import { mapStyles } from "./mapStyles";

import "@retailtune/vanilla-ui-core/styles/accordion/Accordion.css";
// import "@retailtune/vanilla-ui-core/styles/autocomplete/Autocomplete.css";
import "@retailtune/vanilla-ui-core/styles/back-to-top/Back-to-top.css";
import "@retailtune/vanilla-ui-core/styles/sidebar/Sidebar.css";
import "@retailtune/vanilla-ui-core/styles/toast/Toast.css";
import { dataLayerPush } from "./analytics";

// --- Type definitions

// global variables defined in index.php
declare var language: string;

interface PositionState {
  defaultPosition: Position;
  userPosition: Position;
  currentPosition: Position;
}

interface StoreFilter {
  storeTypes: string[];
  services: number[];
}

// user device type
let currentDevice = getDevice();
let translations: Translations;
let serverTimeInMillis: number;
let today: string;

// toast trigger function
let toast: (message: string) => void;

// position related
let position: PositionState;

// stores related
let allServices: Service[];
let allStores: Store[];
let filteredStores: Store[];
const storeMarkersMap = new Map<string, google.maps.Marker>();
const storeCardsMap = new Map<string, HTMLLIElement>();

// unique countries tag
let uniqueCountriyTags: string[];
// allStores unique filter values
let uniqueStoreTypes: string[];
let uniqueStoreTypeNames: string[];
let uniqueServices: number[];
// filteredStores unique flter values
let filteredUniqueStoreTypes: string[];
let filteredUniqueStoreTypeNames: string[];
let filteredUniqueServices: number[];

const storeFilter: StoreFilter = {
  services: [],
  storeTypes: [],
};

let googleMap: google.maps.Map;
let userMarker: google.maps.Marker;
let infoWindow: google.maps.InfoWindow;
let lastClickedMarker: { storeCode: string; origin: CTAOrigin } | null;
let markerCluster: MarkerClusterer;
// driving directions related
let directionsDestinationStoreCode: string | null;
let directionsOriginPosition: Position;
let directionsDestinationPosition: Position;
let destinationStoreMarker: google.maps.Marker;
let travelMode: google.maps.TravelMode;
let directionsInstructions: google.maps.DirectionsStep[];
let directionsService: google.maps.DirectionsService;
let directionsRenderer: google.maps.DirectionsRenderer;

// consent related
let userPositionConsent = !!getExpirable<boolean>(USER_POSITION_CONSENT);

// --- Function definitions
const validateHostname = async () => {
  const response = await fetch(RT_API_VALIDATE_HOSTNAME, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.RETAILTUNE_KEY}`,
    },
  });
  if (response.status !== 200) throw new Error("Please use a valid API key");
  const data = await response.json();
  return `https://${data.assetHostname}`;
};

const fetchServerTime = async () => {
  const response = await fetch(RT_API_TIME_GET, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${process.env.RETAILTUNE_KEY}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Cannot fetch server time");
  }

  return response.json();
};

const fetchStores = async (language: string) => {
  const response = await fetch(RT_API_STORES_GET, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.RETAILTUNE_KEY}`,
    },
    body: JSON.stringify({ language }),
  });
  if (response.status !== 200) throw new Error(`[ERROR] cannot fetch stores (status ${response.status})`);

  allStores = await response.json();

  allStores = allStores.map(s => {
    let index;
    if ((index = s.storeTypeLabels.indexOf(STORE_TYPE_GAP_CORNER_OVS_DIVISION)) !== -1) {
      s.storeTypeLabels[index] = STORE_TYPE_GAP_CORNER;
    }
    return s;
  });
  // for (let i = 0; i < 100; ++i) {
  //   for (const store of allStores) {
  //     tmp.push(store);
  //   }
  // }

  // allStores = tmp;
  console.log(`allStores: `, allStores);
};

const fetchServices = async (language: string) => {
  const response = await fetch(RT_API_SERVICES_GET, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.RETAILTUNE_KEY}`,
    },
    body: JSON.stringify({ language }),
  });
  if (response.status !== 200) throw new Error(`[ERROR] cannot fetch services (status ${response.status})`);

  allServices = await response.json();
  // console.log(allServices);
};

const fetchTranslations = async (language: string) => {
  const response = await fetch(`${hostname}/translations/translations-${language}.json`, {
    method: "GET",
  });
  if (response.status !== 200) {
    throw new Error(`[ERROR] cannot fetch translations (status ${response.status})`);
  }

  translations = await response.json();
};

const getUserPosition = async () => {
  const newPosition = await fetchUserPosition(position.defaultPosition);
  // updating position info with the new user position
  position.userPosition = newPosition;
  position.currentPosition = newPosition;
  directionsOriginPosition = newPosition;

  const latLng = positionToLatLngLiteral(position.currentPosition);
  userMarker.setPosition(latLng);
  googleMap.setCenter(latLng);

  // sendind analytics event
  switch (newPosition.type) {
    case "html5":
      newPosition.origin === "fetched";
      // ? window.gaEvent("StoreLocator", "Geo", "Success")
      // : window.gaEvent("StoreLocator", "Geo", "SuccessCookies");
      break;
    case "ip":
      toast(translations.position_fetch_problem);
      newPosition.origin === "fetched";
      // ? window.gaEvent("StoreLocator", "GeoIP", "Success")
      // : window.gaEvent("StoreLocator", "GeoIP", "SuccessCookies");
      break;
    case "default":
      toast(translations.position_fetch_problem);
      // window.gaEvent("StoreLocator", "GeoDefault", "Success");
      break;
  }
};

const shouldSkipStore = (store: Store) => {
  // filter for store type
  let hasOneMatchingType = false;
  for (let i = 0; i < storeFilter.storeTypes.length; ++i) {
    for (let j = 0; j < store.storeTypeLabels.length; ++j) {
      if (storeFilter.storeTypes[i] === store.storeTypeLabels[j]) {
        hasOneMatchingType = true;
        break;
      }
    }
    if (hasOneMatchingType) break;
  }
  if (storeFilter.storeTypes.length > 0 && !hasOneMatchingType) {
    return true;
  }

  // filter for services
  let hasOneMatchingService = false;
  for (let i = 0; i < storeFilter.services.length; ++i) {
    for (let j = 0; j < store.services.length; ++j) {
      if (storeFilter.services[i] === store.services[j]) {
        hasOneMatchingService = true;
        break;
      }
    }
    if (hasOneMatchingService) break;
  }
  if (storeFilter.services.length > 0 && !hasOneMatchingService) {
    return true;
  }

  return false;
};

const updateStoreRelatedData = () => {
  //const storeTypeNamesSet = new Set<string>();
  const countryTagsSet = new Set<string>();
  const storeTypesSet = new Set<string>();
  const storeTypeNamesMap = new Map<string, string>();
  const servicesSet = new Set<number>();

  for (let i = 0; i < allStores.length; ++i) {
    // this should not be influenced by any filter
    countryTagsSet.add(allStores[i].country.tagISO31661Alpha2);

    let a = [];
    let b = [];

    for (const type of allStores[i].storeTypeLabels) {
      storeTypesSet.add(type);
      a.push(type);
    }

    for (const typeName of allStores[i].storeTypes) {
      b.push(typeName);
    }
    for (let i = 0; i < a.length; ++i) {
      storeTypeNamesMap.set(a[i], b[i]);
    }

    for (const service of allStores[i].services) {
      servicesSet.add(service);
    }
  }
  uniqueCountriyTags = Array.from(countryTagsSet);
  uniqueStoreTypes = Array.from(storeTypesSet);
  uniqueStoreTypeNames = uniqueStoreTypes.map(a => storeTypeNamesMap.get(a)!);
  uniqueServices = Array.from(servicesSet);

  // console.table({ storeTypes: uniqueStoreTypes, storeNames: uniqueStoreTypeNames});
};

const handleElementClick = (storeCode: string, origin: CTAOrigin) => {
  const store = filteredStores.find(s => s.storeCode === storeCode);
  // the scroll behavior of the list on a marker click is disabled on mobile
  if (!store || currentDevice !== "desktop") return;

  switch (origin) {
    case "list":
      const marker = storeMarkersMap.get(storeCode);
      if (!marker) break;
      const content = createInfoWindowContent(
        store,
        {
          btnDrivingDirections: translations.portami_qui,
          btnInfoAndPromotions: translations.info_e_promozioni,
        },
        {
          handleElementClick: e => {
            e.stopPropagation();
            handleElementClick(store.storeCode, "map");
          },
          handleInfoClick: e => {
            e.stopPropagation();
            handleStoreInfoClick(store.storeCode, "map");
          },
          handleDirectionsClick: e => {
            e.stopPropagation();
            handleDrivingDirectionsClick(store.storeCode, "map");
          },
        }
      );
      infoWindow.setContent(content);
      infoWindow.open({
        map: googleMap,
        anchor: marker,
      });
      // zoom in on the newly opened info window
      googleMap.setZoom(12);
      break;
    case "map":
      const storesCard = document.getElementById(`rt_store_card_${storeCode}`);
      storesCard?.scrollIntoView({ behavior: "auto", block: "nearest" });
      break;
  }
  const city = store.postalCode ? `${store.city}-${store.postalCode}` : store.city;
  dataLayerPush({ eventname: "store_locator_click", type: store.storeTypes[0], store: store.name, city });
};

const handleDrivingDirectionsClick = (storeCode: string, origin: CTAOrigin) => {
  const store = filteredStores.find(s => s.storeCode === storeCode);
  if (!store) return;
  if (currentDevice === "desktop") {
    directionsDestinationStoreCode = storeCode;
    updateMapWithDirections();
  } else {
    window.open(getGoogleMapsLink(store), "_blank");
  }
  const city = store.postalCode ? `${store.city}-${store.postalCode}` : store.city;
  dataLayerPush({
    eventname: "store_locator_detail",
    type: store.storeTypes[0],
    store: store.name,
    city,
    action: `driving_directions/${origin}`,
  });
};

const handleStoreInfoClick = (storeCode: string, origin: CTAOrigin) => {
  const store = filteredStores.find(s => s.storeCode === storeCode);
  if (!store) return;
  const city = store.postalCode ? `${store.city}-${store.postalCode}` : store.city;
  dataLayerPush({
    eventname: "store_locator_detail",
    type: store.storeTypes[0],
    store: store.name,
    city,
    action: `store_details/${origin}`,
  });
  window.open(store.storeLink);
};

const getStoreIcon = (store: Store) => {
  let iconUrl = "";
  for (let i = 0; i < store.storeTypeLabels.length; ++i) {
    iconUrl = selectStoreIcon(store.storeTypeLabels[i]);
    if (iconUrl) break;
  }
  return iconUrl;
};

const createStoreCard = (store: Store) => {
  const openingTime = createStoreOpeningTimeText(serverTimeInMillis, store);

  const storeCardEl = document.createElement("li");
  storeCardEl.id = `rt_store_card_${store.storeCode}`;
  storeCardEl.classList.add("rt-store");
  storeCardEl.onclick = () => handleElementClick(store.storeCode, "list");

  const storeHeadingEl = document.createElement("div");
  storeHeadingEl.classList.add("rt-store__heading");
  const headingImgEl = document.createElement("img");
  headingImgEl.classList.add("rt-store-type-img");
  headingImgEl.src = getStoreIcon(store);
  headingImgEl.alt = "store-icon";
  const headingStoreTypeEl = document.createElement("p");
  headingStoreTypeEl.classList.add("rt-store-type-label");
  headingStoreTypeEl.textContent = store.storeTypes[0];
  const headingNameEl = document.createElement("p");
  headingNameEl.classList.add("rt-store-name");
  let name = store.name;
  if (store.storeTypeLabels.includes(STORE_TYPE_GAP_CORNER)) {
    name = name.replace("GAP", "Coin").replace("Gap", "");
  }
  headingNameEl.textContent = name;

  storeHeadingEl.appendChild(headingImgEl);
  storeHeadingEl.appendChild(headingStoreTypeEl);
  storeHeadingEl.appendChild(headingNameEl);

  const storeInfoEl = document.createElement("div");
  storeInfoEl.classList.add("rt-store__info");
  const storeAddressEl = document.createElement("p");
  storeAddressEl.classList.add("rt-store__info-address-street");
  storeAddressEl.textContent = store.address1;
  const storeCityEl = document.createElement("p");
  storeCityEl.classList.add("rt-store__info-address-city");
  storeCityEl.textContent = `${store.city} ${store.province ? `(${store.province}),` : ","} ${store.postalCode}, ${
    store.country.name
  }`;
  const storePhoneEl = document.createElement("a");
  storePhoneEl.classList.add("rt-store__info-phone");
  storePhoneEl.href = `tel:${store.phone}`;
  storePhoneEl.textContent = store.phone;
  const storeHoursEl = document.createElement("div");
  storeHoursEl.classList.add("rt-store__hours");

  if (openingTime !== "") {
    // creating the opening hours accordion
    const openingHoursContentEl = document.createElement("div");
    const aMondayDate = new Date(Date.parse("2022-09-19"));
    for (let i = 0; i < store.openingHours.length; ++i) {
      // building a copy of mondayDate since we are going to mutate it
      const monday = new Date(aMondayDate);
      const currentDate = new Date(monday.setDate(aMondayDate.getDate() + i));
      const dayName = currentDate.toLocaleString(language, { weekday: "short" });
      let text = store.openingHours[i];
      if (text === "" || text === "x") {
        text = translations.oggi_chiuso;
      } else {
        text = store.openingHours[i];
      }
      const hourEl = document.createElement("div");
      hourEl.classList.add("rt-hour");
      const dayNameEl = document.createElement("span");
      dayNameEl.classList.add("rt-hour__weekday");
      dayNameEl.textContent = dayName;
      const openingHourEl = document.createElement("time");
      openingHourEl.classList.add("rt-hour__opening");
      openingHourEl.textContent = text;
      hourEl.appendChild(dayNameEl);
      hourEl.appendChild(openingHourEl);
      openingHoursContentEl.appendChild(hourEl);
    }

    const openingText =
      openingTime !== "x"
        ? `<span class="rt-store-current-status rt-store--open">${translations.oggi_aperto}</span><time class="rt-store-current-hours">${openingTime}</time>`
        : `<span class="rt-store-current-status rt-store--closed">${translations.oggi_chiuso}</span>`;

    createAccordion({
      anchor: storeHoursEl,
      message: openingText,
      content: openingHoursContentEl,
    });
  }

  storeInfoEl.appendChild(storeAddressEl);
  storeInfoEl.appendChild(storeCityEl);
  storeInfoEl.appendChild(storePhoneEl);

  const storeCtaEl = document.createElement("div");
  storeCtaEl.classList.add("rt-store__cta");
  const btnInfoEl = document.createElement("button");
  btnInfoEl.classList.add("rt-store__cta-info", "rt-btn", "rt-btn-cta");
  btnInfoEl.textContent = translations.info_e_promozioni;
  btnInfoEl.onclick = () => handleStoreInfoClick(store.storeCode, "list");
  const btnDirectionsEl = document.createElement("button");
  btnDirectionsEl.classList.add("rt-store__cta-directions", "rt-btn", "rt-btn-cta");
  btnDirectionsEl.textContent = translations.portami_qui;
  btnDirectionsEl.onclick = e => {
    e.stopPropagation();
    handleDrivingDirectionsClick(store.storeCode, "list");
  };

  storeCtaEl.appendChild(btnInfoEl);
  storeCtaEl.appendChild(btnDirectionsEl);

  storeCardEl.appendChild(storeHeadingEl);
  storeCardEl.appendChild(storeInfoEl);
  storeCardEl.appendChild(storeHoursEl);
  storeCardEl.appendChild(storeCtaEl);

  return storeCardEl;
};

const setupStores = () => {
  const start = performance.now();
  filteredStores = [];

  for (let i = 0; i < allStores.length; ++i) {
    const storeCode = allStores[i].storeCode;
    if (!(storeCardsMap.get(storeCode) && storeMarkersMap.get(storeCode))) {
      allStores[i].distance = 0;

      // creating ancd caching the store card for this store
      const storeCard = createStoreCard(allStores[i]);
      storeCardsMap.set(storeCode, storeCard);

      // creating and caching the marker for this store
      const marker = new google.maps.Marker({
        position: { lat: allStores[i].latitude, lng: allStores[i].longitude },
        icon: getStoreIcon(allStores[i]),
      });
      marker.addListener("click", () => {
        const content = createInfoWindowContent(
          allStores[i],
          {
            btnDrivingDirections: translations.portami_qui,
            btnInfoAndPromotions: translations.info_e_promozioni,
          },
          {
            handleElementClick: e => {
              e.stopPropagation();
              handleElementClick(allStores[i].storeCode, "map");
            },
            handleInfoClick: e => {
              e.stopPropagation();
              handleStoreInfoClick(allStores[i].storeCode, "map");
            },
            handleDirectionsClick: e => {
              e.stopPropagation();
              handleDrivingDirectionsClick(allStores[i].storeCode, "map");
            },
          }
        );
        infoWindow.setContent(content);
        infoWindow.open({ anchor: marker });

        lastClickedMarker = { storeCode, origin: "map" };
        handleElementClick(storeCode, "map");
      });
      storeMarkersMap.set(storeCode, marker);
    }

    if (shouldSkipStore(allStores[i])) {
      continue;
    }

    filteredStores.push(allStores[i]);
  }
  // filteredStores.sort(sortStoresByPriority);
  const end = performance.now();
  // console.log(`setupStores time: ${end - start}`);
};

const updateStores = (shouldUpdateMarkers: boolean) => {
  const mapBounds = googleMap.getBounds();
  const mapCenter = googleMap.getCenter();
  if (!(mapBounds && mapCenter)) {
    // console.log("updateFilteredstores - timeout");
    setTimeout(() => updateStores(shouldUpdateMarkers), 100);
    return;
  }

  const start = performance.now();

  let storeMarkers: google.maps.Marker[];
  if (shouldUpdateMarkers) {
    storeMarkers = new Array<google.maps.Marker>(filteredStores.length);
  } else {
    storeMarkers = [];
  }

  const latLng = { lat: 0, lng: 0 };

  let storesEl = document.getElementById("rt_stores") as HTMLUListElement;
  storesEl.innerHTML = "";

  const visibleStores: Store[] = [];

  const getDistance = getRadialDistanceFn(mapCenter.lat(), mapCenter.lng());

  for (let i = 0; i < filteredStores.length; ++i) {
    const storeCode = filteredStores[i].storeCode;
    if (shouldUpdateMarkers) {
      // populating markers
      storeMarkers[i] = storeMarkersMap.get(storeCode)!;
    }

    // populating store cards
    latLng.lat = filteredStores[i].latitude;
    latLng.lng = filteredStores[i].longitude;
    if (mapBounds.contains(latLng)) {
      visibleStores.push(filteredStores[i]);
    }
  }

  visibleStores.sort(sortStoresByPriority);

  for (let i = 0; i < visibleStores.length; ++i) {
    visibleStores[i].distance = getDistance(visibleStores[i].latitude, visibleStores[i].longitude);
    storesEl.appendChild(storeCardsMap.get(visibleStores[i].storeCode)!);
  }

  if (shouldUpdateMarkers) {
    markerCluster.clearMarkers();
    markerCluster.addMarkers(storeMarkers);
  }

  const end = performance.now();
  // console.log(`updateStores time: ${end - start}`);
};

const directionsDebounce = createDebounceFn()(400);
const updateMapWithDirections = () =>
  directionsDebounce(() => {
    const destinationStore = allStores.find(s => s.storeCode === directionsDestinationStoreCode);
    if (!destinationStore) return;

    directionsDestinationPosition = createPosition({
      latitude: destinationStore.latitude,
      longitude: destinationStore.longitude,
    });

    const origin = positionToLatLngLiteral(directionsOriginPosition);
    const destination = positionToLatLngLiteral(directionsDestinationPosition);
    const request: google.maps.DirectionsRequest = {
      origin,
      destination,
      travelMode,
    };

    directionsInstructions = [];

    directionsService.route(request, (result, status) => {
      if (
        !result ||
        status === google.maps.DirectionsStatus.INVALID_REQUEST ||
        status === google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED ||
        status === google.maps.DirectionsStatus.ZERO_RESULTS
      ) {
        return toast(translations.no_driving_directions_found);
      }
      if (status === google.maps.DirectionsStatus.OK) {
        clearDrivingDirectionsInstructions();
        markerCluster.clearMarkers();
        showDirectionsPane(true);

        const route = result.routes[0].legs[0];

        directionsInstructions = route.steps;
        directionsRenderer.setOptions({
          directions: result,
          suppressMarkers: true,
        });
        userMarker.setIcon(`${hostname}/img/icon/pin/user-origin.svg`);
        userMarker.setPosition(origin);
        destinationStoreMarker.setPosition(destination);
        destinationStoreMarker.setIcon(getStoreIcon(destinationStore));
        destinationStoreMarker.setVisible(true);

        // updating the directions pane UI
        const destinationInputEl = document.getElementById("rt_destination_input_text")!;
        destinationInputEl.textContent = `${destinationStore.name} - ${destinationStore.city}`;

        const directionsInstructionsEl = document.getElementById("rt_directions_instructions")!;
        for (let i = 0; i < directionsInstructions.length; ++i) {
          const step = document.createElement("li");
          step.classList.add("rt-instruction");
          step.innerHTML = `<strong>${i + 1}.</strong><div>${directionsInstructions[i].instructions}</div>`;
          directionsInstructionsEl.appendChild(step);
        }

        // create scroll button for the directions pane
        createScrollButton({
          anchor: "rt_directions_instructions",
          scrollingElId: "rt_directions_instructions",
        });
      }
    });
  });

const showStoresOrDirections = () => {
  if (directionsDestinationStoreCode && currentDevice === "desktop") {
    updateMapWithDirections();
  } else {
    updateStores(true);
    googleMap.setZoom(9);
  }
};

const clearDrivingDirectionsInstructions = () => {
  directionsInstructions = [];
  const directionsInstructionsEl = document.getElementById("rt_directions_instructions")!;
  directionsInstructionsEl.innerHTML = "";
};

const clearDrivingDirections = () => {
  directionsDestinationStoreCode = null;
  showDirectionsPane(false);
  directionsRenderer.setDirections({ routes: [] });
  clearDrivingDirectionsInstructions();
  // reset travel mode value and appearance
  travelMode = google.maps.TravelMode.DRIVING;
  document.querySelectorAll(".rt-travel-mode").forEach(el => el.classList.remove("rt-travel-mode--selected"));
  document.getElementById(`rt_${google.maps.TravelMode.DRIVING}`)!.classList.add("rt-travel-mode--selected");
  destinationStoreMarker.setVisible(false);
  userMarker.setIcon(`${hostname}/img/icon/pin/user-origin.svg`);
  userMarker.setPosition(positionToLatLngLiteral(position.currentPosition));
};

const showDirectionsPane = (shouldShow: boolean) => {
  const directionsPaneEl = document.getElementById("rt_directions_pane")!;
  if (shouldShow) {
    directionsPaneEl.classList.add("rt-directions-pane--visible");
  } else {
    directionsPaneEl.classList.remove("rt-directions-pane--visible");
  }
};

const showConsentModal = (shouldShow: boolean) => {
  const consentModalEl = document.getElementById("rt_position_consent_modal")!;
  if (shouldShow) {
    consentModalEl.classList.add("rt-position-consent-modal--visible");
  } else {
    consentModalEl.classList.remove("rt-position-consent-modal--visible");
  }
};

(window as any).followBreadcrumbs = () => {
  dataLayerPush({
    eventname: "breadcrumbs",
    label: "Home",
    position: 1,
  });
};

const updateFilterObject = <T extends keyof StoreFilter>(shouldAddTofilter: boolean, key: T, value: string) => {
  switch (key) {
    case "services":
      if (shouldAddTofilter) {
        storeFilter.services.push(+value);
        storeFilter.services = Array.from(new Set(storeFilter.services));
      } else {
        storeFilter.services = storeFilter.services.filter(type => type !== +value);
      }
      break;
    case "storeTypes":
      if (shouldAddTofilter) {
        storeFilter.storeTypes.push(value);
        storeFilter.storeTypes = Array.from(new Set(storeFilter.storeTypes));
      } else {
        storeFilter.storeTypes = storeFilter.storeTypes.filter(type => type !== value);
      }
      break;
    default:
      console.warn(`cannot apply filter of type '${key}'`);
  }
};

const getTempFilteredStores = <T extends keyof StoreFilter>(excludedKey: T) => {
  const filteredWithExclusion: Store[] = [];
  const entries = Object.entries(storeFilter);
  for (let i = 0; i < allStores.length; ++i) {
    let shouldFilterStore = 1;
    for (let j = 0; j < entries.length; ++j) {
      if (!shouldFilterStore) {
        break;
      }

      const [key, value] = entries[j];

      if (excludedKey === key) {
        continue;
      }

      let hasOneMatch = 0;

      switch (key) {
        case "storeTypes":
          hasOneMatch = 0;
          for (let k = 0; k < allStores[i].storeTypeLabels.length; ++k) {
            if ((value as string[]).includes(allStores[i].storeTypeLabels[k])) {
              hasOneMatch = 1;
              break;
            }
          }
          if (storeFilter.storeTypes.length > 0) {
            shouldFilterStore &= hasOneMatch;
          }
          break;
        case "services":
          hasOneMatch = 0;
          for (let k = 0; k < allStores[i].services.length; ++k) {
            if ((value as number[]).includes(allStores[i].services[k])) {
              hasOneMatch = 1;
              break;
            }
          }
          if (storeFilter.services.length > 0) {
            shouldFilterStore &= hasOneMatch;
          }
          break;
        default:
          console.warn(`filter key '${key}' is not supported (filter will not be applied)`);
      }
    }
    if (shouldFilterStore) {
      filteredWithExclusion.push(allStores[i]);
    }
  }
  return filteredWithExclusion;
};

const handleStoreTypeFilterSelection = () => {
  // filter allStores using storefilter excluding store-type filters
  const tmp = getTempFilteredStores("services");

  // get all the payement method filters and create a map to hold teir occurrence
  const payementMethodFilters = document.querySelectorAll<HTMLLabelElement>(
    "#rt_payement_methods_accordion_content .rt-filter-option__label"
  );
  const payementMethodsMap = new Map<number, boolean>();
  for (let i = 0; i < payementMethodFilters.length; ++i) {
    const value = payementMethodFilters[i].getAttribute("filter-value")!;
    payementMethodsMap.set(+value, false);
  }

  for (let i = 0; i < tmp.length; ++i) {
    // for each filtered store, check every non store-type filter:

    // checking every payement method
    for (let j = 0; j < tmp[i].services.length; ++j) {
      payementMethodsMap.set(tmp[i].services[j], true);
    }
  }

  // update the UI
  let filterHasChanged = false;
  for (let i = 0; i < payementMethodFilters.length; ++i) {
    const value = payementMethodFilters[i].getAttribute("filter-value")!;
    if (payementMethodsMap.get(+value)) {
      payementMethodFilters[i].classList.remove("rt-filter-option__label--disabled");
    } else {
      payementMethodFilters[i].classList.add("rt-filter-option__label--disabled");
      const inputEl = payementMethodFilters[i].firstChild as HTMLInputElement;
      if (inputEl.checked) {
        inputEl.checked = false;
        updateFilterObject(inputEl.checked, "services", inputEl.value);
        filterHasChanged = true;
      }
    }
  }
  if (filterHasChanged) {
    // if the filter has been changed programmatically, the handler functions for the filters must be invoked
    handlePayementMethodFilterSelection();
  }
};

const handlePayementMethodFilterSelection = () => {
  // filter allStores using storefilter excluding store-type filters
  const tmp = getTempFilteredStores("storeTypes");

  // get all the store type filters and create a map to hold teir occurrence
  const storeTypeFilters = document.querySelectorAll<HTMLLabelElement>(
    "#rt_store_type_accordion_content .rt-filter-option__label"
  );
  const storeTypeMap = new Map<string, boolean>();
  for (let i = 0; i < storeTypeFilters.length; ++i) {
    const value = storeTypeFilters[i].getAttribute("filter-value")!;
    storeTypeMap.set(value, false);
  }

  for (let i = 0; i < tmp.length; ++i) {
    // for each filtered store, check every non payement method filter:

    // checking every store type
    for (let j = 0; j < tmp[i].storeTypeLabels.length; ++j) {
      storeTypeMap.set(tmp[i].storeTypeLabels[j], true);
    }
  }

  // update the UI
  let filterHasChanged = false;
  for (let i = 0; i < storeTypeFilters.length; ++i) {
    const value = storeTypeFilters[i].getAttribute("filter-value")!;
    if (storeTypeMap.get(value)) {
      storeTypeFilters[i].classList.remove("rt-filter-option__label--disabled");
    } else {
      storeTypeFilters[i].classList.add("rt-filter-option__label--disabled");
      const inputEl = storeTypeFilters[i].firstChild as HTMLInputElement;
      if (inputEl.checked) {
        inputEl.checked = false;
        updateFilterObject(inputEl.checked, "storeTypes", inputEl.value);
        filterHasChanged = true;
      }
    }
  }
  if (filterHasChanged) {
    // if the filter has been changed programmatically, the handler functions for the filters must be invoked
    handleStoreTypeFilterSelection();
  }
};

const createStoreTypeFilters = (anchor: HTMLElement) => {
  // creating store types accordion filter
  const storeTypesAccordionContentEl = document.createElement("div");
  storeTypesAccordionContentEl.id = "rt_store_type_accordion_content";
  for (let i = 0; i < uniqueStoreTypes.length; ++i) {
    if (uniqueStoreTypes[i] === STORE_TYPE_GAP_CORNER_OVS_DIVISION) {
      // skip this store type
      continue;
    }
    const labelEl = document.createElement("label");
    labelEl.classList.add("rt-filter-option__label");
    labelEl.setAttribute("filter-value", uniqueStoreTypes[i]);
    const inputEl = document.createElement("input");
    inputEl.type = "checkbox";
    inputEl.classList.add("rt-filter-option__input");
    inputEl.value = uniqueStoreTypes[i];
    const imgEl = document.createElement("img");
    imgEl.src = selectStoreIcon(uniqueStoreTypes[i]);
    imgEl.alt = "store type";

    labelEl.appendChild(inputEl);
    labelEl.appendChild(imgEl);
    labelEl.innerHTML += uniqueStoreTypeNames[i];

    labelEl.addEventListener("change", e => {
      e.stopPropagation();
      const target = e.target as HTMLInputElement;
      updateFilterObject(target.checked, "storeTypes", target.value);
      handleStoreTypeFilterSelection();
      setupStores();
      updateStores(true);

      dataLayerPush({
        eventname: "store_locator_filter",
        type: "single_filter",
        filter: `type/${target.value}`,
      });
    });

    storeTypesAccordionContentEl.appendChild(labelEl);
  }
  createAccordion({
    anchor,
    message: translations.tipologie_negozio,
    content: storeTypesAccordionContentEl,
  });
};

const createPayementMethodsFilter = (anchor: HTMLElement) => {
  // creating payement methods accordion filter
  const payementMethodsAccordionContentEl = document.createElement("div");
  payementMethodsAccordionContentEl.id = "rt_payement_methods_accordion_content";
  for (let i = 0; i < uniqueServices.length; ++i) {
    // filtring out all services that have groupId != 14 since they are not payement methods
    const service = allServices.find(s => s.groupId === 14 && s.id === uniqueServices[i]);
    if (!service) {
      continue;
    }
    const labelEl = document.createElement("label");
    labelEl.classList.add("rt-filter-option__label");
    labelEl.setAttribute("filter-value", uniqueServices[i].toString());
    const inputEl = document.createElement("input");
    inputEl.type = "checkbox";
    inputEl.classList.add("rt-filter-option__input");
    inputEl.value = service.id.toString();
    const imgEl = document.createElement("img");
    imgEl.classList.add("img--cover");
    imgEl.src = service.icon;
    imgEl.alt = "store type";

    labelEl.appendChild(inputEl);
    labelEl.appendChild(imgEl);
    // service.name is split to get the actual name since its in the form "SERVICES_GAP:service_name"
    const serviceName = service.name.split(":")[1];
    labelEl.innerHTML += serviceName;

    labelEl.addEventListener("change", e => {
      e.stopPropagation();
      const target = e.target as HTMLInputElement;
      updateFilterObject(target.checked, "services", target.value);
      handlePayementMethodFilterSelection();
      setupStores();
      updateStores(true);

      dataLayerPush({
        eventname: "store_locator_filter",
        type: "single_filter",
        filter: `payement/${serviceName}`,
      });
    });

    payementMethodsAccordionContentEl.appendChild(labelEl);
  }
  createAccordion({
    anchor,
    message: translations.metodi_di_pagamento,
    content: payementMethodsAccordionContentEl,
  });
};

const createFilterSidebarContent = () => {
  const sidebarContentEl = document.createElement("div");
  sidebarContentEl.classList.add("rt-sidebar-content");

  // * Sidebar Header
  const sidebarHeaderEl = document.createElement("div");
  sidebarHeaderEl.classList.add("rt-sidebar__header");
  const titleEl = document.createElement("div");
  titleEl.classList.add("rt-title");
  titleEl.textContent = translations.filters;
  const closeFiltersEl = document.createElement("img");
  closeFiltersEl.id = "rt_close_filters";
  closeFiltersEl.classList.add("rt-close");
  closeFiltersEl.src = `${hostname}/img/icon/close.svg`;
  closeFiltersEl.alt = "X";
  sidebarHeaderEl.appendChild(titleEl);
  sidebarHeaderEl.appendChild(closeFiltersEl);

  // * Sidebar Body
  const sidebarBodyEl = document.createElement("div");
  sidebarBodyEl.classList.add("rt-sidebar__body");
  sidebarBodyEl.id = "rt_sidebar_body";

  createStoreTypeFilters(sidebarBodyEl);
  createPayementMethodsFilter(sidebarBodyEl);

  // * Sidebar Footer
  const sidebarFooterEl = document.createElement("div");
  sidebarFooterEl.classList.add("rt-sidebar__footer");
  const resetFilterBtn = document.createElement("btn");
  resetFilterBtn.id = "rt_btn_reset_filters";
  resetFilterBtn.classList.add("rt-btn-reset-filters", "rt-btn", "rt-btn-secondary");
  resetFilterBtn.textContent = translations.azzera_filtri;
  const applyFiltersBtn = document.createElement("btn");
  applyFiltersBtn.id = "rt_btn_apply_filters";
  applyFiltersBtn.classList.add("rt-btn-apply-filters", "rt-btn", "rt-btn-primary");
  applyFiltersBtn.textContent = translations.applica_filtri;
  sidebarFooterEl.appendChild(resetFilterBtn);
  sidebarFooterEl.appendChild(applyFiltersBtn);

  sidebarContentEl.appendChild(sidebarHeaderEl);
  sidebarContentEl.appendChild(sidebarBodyEl);
  sidebarContentEl.appendChild(sidebarFooterEl);
  return sidebarContentEl;
};

// # Main function
async function main() {
  // fetching data
  const [defaultPosition, time] = await Promise.all([
    fetchUserDefaultPosition(process.env.RETAILTUNE_KEY!),
    fetchServerTime(),
    fetchStores(language),
    fetchServices(language),
    fetchTranslations(language),
    validateHostname(),
  ]);

  // # State initialization

  position = {
    defaultPosition,
    userPosition: defaultPosition,
    currentPosition: defaultPosition,
  };

  directionsOriginPosition = defaultPosition;

  serverTimeInMillis = time.serverTime * 1000;
  today = new Date(serverTimeInMillis).toLocaleString(language, {
    timeZone: "UTC",
    weekday: "long",
  });

  updateStoreRelatedData();
  setupStores();

  // # Map initialization
  const mapEl = document.getElementById("rt_map") as HTMLDivElement;
  const mapCenter = positionToLatLngLiteral(position.currentPosition);

  googleMap = new google.maps.Map(mapEl, {
    zoom: 7,
    center: mapCenter,
    mapTypeControl: false,
    streetViewControl: false,
    styles: mapStyles,
  });

  userMarker = new google.maps.Marker({
    map: googleMap,
    position: mapCenter,
    icon: `${hostname}/img/icon/pin/user-origin.svg`,
  });

  markerCluster = new MarkerClusterer({
    map: googleMap,
    markers: [],
    // algorithm: new CustomGridAlgorithm(googleMap, 20),
    renderer: new CustomRenderer(`${hostname}/img/icon/cluster/cluster.svg`, "#000", infoWindow),
  });

  infoWindow = new google.maps.InfoWindow();

  // # Driving Directions initialization
  destinationStoreMarker = new google.maps.Marker({
    map: googleMap,
    visible: false,
  });

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    map: googleMap,
    polylineOptions: {
      strokeColor: "#d70101",
      strokeWeight: 3,
    },
  });

  const travelModesEl = document.getElementById("rt_travel_modes")!;

  const createTravelMode = (mode: google.maps.TravelMode, iconUrl: string) => {
    const travelModeEl = document.createElement("li");
    travelModeEl.id = `rt_${mode}`;
    travelModeEl.classList.add("rt-travel-mode");
    if (mode === google.maps.TravelMode.DRIVING) {
      travelModeEl.classList.add("rt-travel-mode--selected");
    }
    const imgEl = document.createElement("img");
    imgEl.classList.add("rt-travel-mode__icon");
    imgEl.src = iconUrl;
    imgEl.alt = mode;

    travelModeEl.addEventListener("click", () => {
      document.querySelectorAll(".rt-travel-mode").forEach(el => el.classList.remove("rt-travel-mode--selected"));
      travelModeEl.classList.add("rt-travel-mode--selected");
      travelMode = mode;
      updateMapWithDirections();
    });
    travelModeEl.appendChild(imgEl);
    travelModesEl.appendChild(travelModeEl);
  };

  createTravelMode(google.maps.TravelMode.DRIVING, `${hostname}/img/icon/driving.svg`);
  createTravelMode(google.maps.TravelMode.WALKING, `${hostname}/img/icon/walking.svg`);
  createTravelMode(google.maps.TravelMode.TRANSIT, `${hostname}/img/icon/transit.svg`);

  travelMode = google.maps.TravelMode.DRIVING;

  // shared Autocomplete search handler
  const searchHandler = createAutocompleteHandler(
    retailTuneAutocompleteHandler(process.env.RETAILTUNE_KEY!, {
      language,
      countries: uniqueCountriyTags,
    }),
    googleAutocompleteHandler()
  );

  // main autocomplete prediction click handler
  const mainPredictionClickHandler = (prediction: PredictionData) => {
    position.currentPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });
    const latLng = positionToLatLngLiteral(position.currentPosition);
    userMarker.setPosition(latLng);
    googleMap.setCenter(latLng);

    if (directionsDestinationStoreCode) {
      clearDrivingDirections();
    }
    updateStores(false);
    googleMap.setZoom(9);

    dataLayerPush({
      eventname: "store_locator_search",
      label: prediction.description,
    });
  };

  const [clearMainAutocomplete] = createAutocomplete({
    anchor: "rt_main_autocomplete_container",
    searchHandler,
    predictionClickHandler: mainPredictionClickHandler,
    zeroResultsMessage: translations.zero_results_message,
    placeholder: translations.autocomplete_placeholder,
    searchIcon: {
      path: `${hostname}/img/icon/search.svg`,
      position: "left",
    },
  });

  // directions autocomplete prediction click handler
  const directionsPredictionClickHandler = (prediction: PredictionData) => {
    directionsOriginPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });
    updateMapWithDirections();
    // window.gaEvent("StoreLocator", "Click", "FreeSearchDirections");
  };

  const [clearDirectionsAutocomplete] = createAutocomplete({
    anchor: "rt_directions_autocomplete_container",
    searchHandler,
    predictionClickHandler: directionsPredictionClickHandler,
    zeroResultsMessage: translations.zero_results_message,
    placeholder: translations.autocomplete_placeholder,
    searchIcon: { path: `${hostname}/img/icon/search.svg`, position: "right" },
  });

  const [updateFiltersVisibility] = createSidebar({
    anchor: "rt_storelocator",
    position: "right",
    content: createFilterSidebarContent(),
  });

  [toast] = createToastMessagesContainer({
    anchor: "rt_storelocator",
    autoClose: 3000,
    position: "top-right",
  });

  const storesEl = document.createElement("ul");
  storesEl.id = "rt_stores";
  storesEl.classList.add("rt-stores");

  const legendEl = createLegend(uniqueStoreTypes, uniqueStoreTypeNames, STORE_TYPE_GAP_CORNER_OVS_DIVISION);

  const mobileStoresContainer = document.getElementById("rt_map_or_stores_container")!;
  const desktopStoresContainer = document.getElementById("rt_controls_container")!;

  const storesContainerEl = document.createElement("div");
  storesContainerEl.id = "rt_stores_container";
  storesContainerEl.classList.add("rt-stores-container");

  storesContainerEl.appendChild(legendEl);
  storesContainerEl.appendChild(storesEl);

  if (currentDevice === "desktop") {
    // mounting stores list in appropriate node
    desktopStoresContainer.appendChild(storesContainerEl);

    // parsing eventual search params
    const params = new URLSearchParams(window.location.search);
    const storeCode = params.get("code");
    directionsDestinationStoreCode = storeCode;
  } else {
    // mounting stores list in appropriate node
    mobileStoresContainer.appendChild(storesContainerEl);
  }

  // creates scroll button for the store list
  createScrollButton({
    anchor: "rt_stores_container",
    scrollingElId: "rt_stores_container",
  });

  showStoresOrDirections();

  showConsentModal(!userPositionConsent);
  if (userPositionConsent) {
    await getUserPosition();
    showStoresOrDirections();
  }

  // # Event Handlers
  // --- binding handlers to the document & window

  //! Removing the ".preload" class on the body as soon as the DOM is loaded
  //! Note that the .preload class only serves the purpose of avoding any transition effects
  //! that can occur when the page first loads.
  //! As soon as the DOM is loaded, the class is removed from the body, and thus transitions can actually occur.
  const resizeDebounce = createDebounceFn()(0);
  window.addEventListener("resize", () =>
    resizeDebounce(() => {
      const newDevice = getDevice();
      if (newDevice !== "desktop" && directionsDestinationStoreCode !== null) {
        // clear directions if mobile
        clearDrivingDirections();
        updateStores(true);
      }

      if (currentDevice !== "desktop" && newDevice === "desktop") {
        // breakpoint from tablet to desktop
        mobileStoresContainer.removeChild(storesContainerEl);
        desktopStoresContainer.appendChild(storesContainerEl);
      } else if (currentDevice === "desktop" && newDevice !== "desktop") {
        // breakpoint from desktop to tablet or mobile
        desktopStoresContainer.removeChild(storesContainerEl);
        mobileStoresContainer.appendChild(storesContainerEl);
      }
      currentDevice = newDevice;
    })
  );

  // --- binding handlers to DOM elements

  const handleBoundsChange = () => {
    if (directionsDestinationStoreCode) {
      return;
    }
    updateStores(false);
    if (lastClickedMarker) {
      // if a marker click has been registered, it calls its handler
      handleElementClick(lastClickedMarker.storeCode, lastClickedMarker.origin);
      lastClickedMarker = null;
    }
  };

  const boundsChangedDebounce = createDebounceFn()(300);
  googleMap.addListener("bounds_changed", () => boundsChangedDebounce(handleBoundsChange));

  const geoControlsEl = document.getElementById("rt_geo_controls")!;
  const filtersButton = document.createElement("button");
  filtersButton.classList.add("rt-btn-filters", "rt-btn", "rt-btn-control");
  filtersButton.textContent = translations.filters;
  filtersButton.addEventListener("click", () => {
    updateFiltersVisibility(true);
    dataLayerPush({
      eventname: "store_locator_filter",
      type: "click",
    });
  });
  geoControlsEl.appendChild(filtersButton);

  const btnNearestStoreEl = document.getElementById("rt_btn_nearest_stores")!;
  btnNearestStoreEl.addEventListener("click", async () => {
    showConsentModal(!userPositionConsent);
    if (userPositionConsent) {
      clearDrivingDirections();
      clearMainAutocomplete();
      await getUserPosition();
      showStoresOrDirections();
    }
    // window.gaEvent("StoreLocator", "Click", "FindNearestStore");
  });

  const btnList = document.getElementById("rt_button_list")!;
  const btnMap = document.getElementById("rt_button_map")!;

  if (currentDevice !== "desktop") {
    btnList.classList.add("rt-toggle--visible");
  }
  btnList.addEventListener("click", () => {
    btnList.classList.remove("rt-toggle--visible");
    btnMap.classList.add("rt-toggle--visible");
    mapEl.classList.remove("rt-map-container--visible");
    dataLayerPush({
      eventname: "store_locator_type_view",
      label: "list",
    });
  });

  btnMap.addEventListener("click", () => {
    btnMap.classList.remove("rt-toggle--visible");
    btnList.classList.add("rt-toggle--visible");
    mapEl.classList.add("rt-map-container--visible");
    dataLayerPush({
      eventname: "store_locator_type_view",
      label: "map",
    });
  });

  const filtersBarCloseBtn = document.getElementById("rt_close_filters")!;
  filtersBarCloseBtn.addEventListener("click", () => {
    updateFiltersVisibility(false);
  });

  const resetFiltersBtn = document.getElementById("rt_btn_reset_filters")!;
  resetFiltersBtn.addEventListener("click", () => {
    const filters = document.querySelectorAll<HTMLInputElement>("#rt_sidebar_body .rt-filter-option__input");
    filters.forEach(checkbox => (checkbox.checked = false));
    storeFilter.services = [];
    storeFilter.storeTypes = [];
    setupStores();
    updateStores(true);
  });

  const applyFiltersBtn = document.getElementById("rt_btn_apply_filters")!;
  applyFiltersBtn.addEventListener("click", () => {
    // it actually jusct close the sidebar
    updateFiltersVisibility(false);
  });

  const directionsPaneCloseIcon = document.getElementById("rt_directions_pane_close")!;
  directionsPaneCloseIcon.addEventListener("click", () => {
    clearDrivingDirections();
    clearDirectionsAutocomplete();
    updateStores(true);
  });

  const btnUseUserPosition = document.getElementById("rt_btn_use_user_position")!;
  btnUseUserPosition.addEventListener("click", async () => {
    showConsentModal(!userPositionConsent);
    if (userPositionConsent) {
      await getUserPosition();
      clearDirectionsAutocomplete();
      showStoresOrDirections();
    }
  });

  const btnConsentModalYes = document.getElementById("rt_btn_consent_modal_yes")!;
  btnConsentModalYes.addEventListener("click", async () => {
    userPositionConsent = true;
    setExpirable(USER_POSITION_CONSENT, {
      value: JSON.stringify(true),
      expiration: createExpirationValue(1, "years"),
    });

    await getUserPosition();
    showStoresOrDirections();
    showConsentModal(false);
    // window.gaEvent("StoreLocator", "Geo", "Agree");
  });

  const btnConsentModalNo = document.getElementById("rt_btn_consent_modal_no")!;
  btnConsentModalNo.addEventListener("click", () => {
    userPositionConsent = false;
    showConsentModal(false);
    // window.gaEvent("StoreLocator", "Geo", "Disagree");
  });
}

window.addEventListener("load", () => {
  // TODO add preload class?
  // document.getElementById("rt_storelocator")!.classList.remove("preload");

  // try load the application
  let interval = setInterval(() => {
    try {
      if (!language) throw new Error("'language' variable not found");
      if (!google) throw new Error("'google' namespace not found");
      clearInterval(interval);
      main();
    } catch (e) {
      console.warn(e);
    }
  }, 100);
});
