import { Cluster, Renderer } from "@googlemaps/markerclusterer";
import { Store } from "@retailtune/types-storelocator/lib/store";

export interface Translations {
  consent_modal_title: string;
  consent_modal_text: string;
  yes: string;
  no: string;
  autocomplete_placeholder: string;
  oggi_aperto: string;
  oggi_chiuso: string;
  position_fetch_problem: string;
  info_e_promozioni: string;
  portami_qui: string;
  zero_results_message: string;
  no_driving_directions_found: string;
  stores_gap: string;
  all_stores: string;
  list: string;
  map: string;
  filters: string;
  tipologie_negozio: string;
  metodi_di_pagamento: string;
  azzera_filtri: string;
  applica_filtri: string;
}

export class CustomRenderer implements Renderer {
  constructor(
    private readonly clusterIcon: string,
    private readonly clusterLabelColor: string,
    private readonly infoWindow: google.maps.InfoWindow
  ) {}

  getClusterSize(markersCount: number) {
    if (markersCount < 10) return new google.maps.Size(50, 50);
    if (markersCount < 50) return new google.maps.Size(60, 60);
    if (markersCount < 100) return new google.maps.Size(70, 70);
    return new google.maps.Size(70, 70);
  }

  render({ count, position }: Cluster): google.maps.Marker {
    const cluster = new google.maps.Marker({
      position,
      label: {
        text: count.toString(),
        color: this.clusterLabelColor,
        fontWeight: "600",
      },
      icon: {
        url: this.clusterIcon,
        scaledSize: this.getClusterSize(count),
      },
    });

    cluster.addListener("click", () => {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
    });

    return cluster;
  }
}

export type CTAOrigin = "map" | "list";

export let hostname = process.env.hostname;

export const STORE_TYPE_GAP_STORE = "Divisione GAP";
export const STORE_TYPE_GAP_OUTLET = "Outlet Gap";
export const STORE_TYPE_GAP_CORNER = "Divisione GAP-Corner in Coin";
export const STORE_TYPE_GAP_CORNER_OVS_DIVISION = "Divisione OVS-Corner Gap";

// ! this should be restored when going live with GAP
// export const selectStoreIcon = (storeType: string) => {
//   let iconUrl = "";
//   switch (storeType) {
//     case STORE_TYPE_GAP_OUTLET:
//       iconUrl = `${hostname}/img/icon/pin/pin-o.svg`;
//       break;
//     case STORE_TYPE_GAP_CORNER:
//       iconUrl = `${hostname}/img/icon/pin/pin-c.svg`;
//       break;
//     default:
//       // iconUrl defaults to "Divisione GAP"
//       iconUrl = `${hostname}/img/icon/pin/pin-g.svg`;
//   }
//   return iconUrl;
// };

export const selectStoreIcon = (storeType: string) => {
  let iconUrl = "";
  switch (storeType) {
    case "DEMO SHOWROOM":
      iconUrl = `${hostname}/img/icon/pin/pin-o.svg`;
      break;
    case "DEMO TRAVEL":
      iconUrl = `${hostname}/img/icon/pin/pin-c.svg`;
      break;
    default:
      // iconUrl defaults to "DEMO STORE"
      iconUrl = `${hostname}/img/icon/pin/pin-g.svg`;
  }
  return iconUrl;
};

export const createLegend = (uniqueStoreTypes: string[], uniqueStoreTypeNames: string[], ...exclusionList: string[]) => {
  const legendEl = document.createElement("ul");
  legendEl.classList.add("rt-store-types");
  for (let i = 0; i < uniqueStoreTypes.length; ++i) {
    if (exclusionList.includes(uniqueStoreTypes[i])) {
      // skip the label generation for this type
      continue;
    }
    
    const storeTypeEl = document.createElement("li");
    storeTypeEl.classList.add("rt-store-type");
    const imgEl = document.createElement("img");
    imgEl.classList.add("rt-store-type__img");
    imgEl.src = selectStoreIcon(uniqueStoreTypes[i]);
    imgEl.alt = "store-type";
    const nameEl = document.createElement("span");
    nameEl.classList.add("rt-store-type__label");
    nameEl.textContent = uniqueStoreTypeNames[i];

    storeTypeEl.appendChild(imgEl);
    storeTypeEl.appendChild(nameEl);
    legendEl.append(storeTypeEl);
  }
  return legendEl;
};

interface InfoWindowTexts {
  btnInfoAndPromotions: string;
  btnDrivingDirections: string;
}

interface InfoWindowEventHandlers {
  handleElementClick: (e: Event) => void;
  handleInfoClick: (e: Event) => void;
  handleDirectionsClick: (e: Event) => void;
}

export const createInfoWindowContent = (
  store: Store,
  text: InfoWindowTexts,
  eventHandlers: InfoWindowEventHandlers
) => {
  const infoWindowEl = document.createElement("div");
  infoWindowEl.id = `info_window_${store.storeCode}`;
  infoWindowEl.classList.add("rt-info-window-content");
  // infoWindowEl.onclick = () => handleElementClick(store.storeCode, "map");
  infoWindowEl.onclick = eventHandlers.handleElementClick;

  const storeNameEl = document.createElement("p");
  storeNameEl.classList.add("rt-store__name");
  storeNameEl.textContent = store.name;

  const storeInfoEl = document.createElement("div");
  storeInfoEl.classList.add("rt-store__info");
  const storeAddressEl = document.createElement("p");
  storeAddressEl.classList.add("rt-store__info-address-street");
  storeAddressEl.textContent = store.address1;
  const storeCityEl = document.createElement("p");
  storeCityEl.classList.add("rt-store__info-address-city");
  storeCityEl.textContent = `${store.city} ${store.province ? `(${store.province}),` : ","} ${store.postalCode}, ${
    store.country.name
  }`;
  const storePhoneEl = document.createElement("a");
  storePhoneEl.classList.add("rt-store__info-phone");
  storePhoneEl.href = `tel:${store.phone}`;
  storePhoneEl.textContent = store.phone;

  storeInfoEl.appendChild(storeAddressEl);
  storeInfoEl.appendChild(storeCityEl);
  storeInfoEl.appendChild(storePhoneEl);

  const storeCtaEl = document.createElement("div");
  storeCtaEl.classList.add("rt-store__cta");
  const infoCtaEl = document.createElement("a");
  infoCtaEl.classList.add("rt-store__cta-info", "rt-btn", "rt-btn-cta");
  infoCtaEl.href = store.storeLink;
  infoCtaEl.textContent = text.btnInfoAndPromotions;
  infoCtaEl.onclick = eventHandlers.handleInfoClick;
  const directionsCtaEl = document.createElement("button");
  directionsCtaEl.classList.add("rt-store__cta-directions", "rt-btn", "rt-btn-cta");
  directionsCtaEl.textContent = text.btnDrivingDirections;
  directionsCtaEl.onclick = eventHandlers.handleDirectionsClick;

  storeCtaEl.appendChild(infoCtaEl);
  storeCtaEl.appendChild(directionsCtaEl);

  infoWindowEl.appendChild(storeNameEl);
  infoWindowEl.appendChild(storeInfoEl);
  infoWindowEl.appendChild(storeCtaEl);

  return infoWindowEl;
};
